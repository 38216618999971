import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TitleSection from "../components/TitleSection"
import ContactSection from "../components/ContactSection"
import Gallery from "../components/Gallery"
import { sectionAbout } from "../assets/style/pages/about.module.scss"

const AboutPage = ({ data, intl }) => {
  const { title, subtitle, text } = data.cockpitAboutPage
  const featuredImage = data.cockpitAboutPage.featuredImage.value.childImageSharp.fluid
  const images = data.allCockpitGallery.edges.map(({ node }) => ({
    id: node.id,
    image: node.image.value,
    source: node.image.value.url,
    title: node.title.value,
    categories: false
  }))

  return (
    <Layout>
      <Seo lang={intl.locale} title={intl.formatMessage({ id: "aboutpage" })} />
      <TitleSection title={title.value} subtitle={subtitle.value} bg={featuredImage}></TitleSection>
      <section className={`section ${sectionAbout}`}>
        <div className="container">
          <p dangerouslySetInnerHTML={{ __html: text.value }}></p>
          <Gallery images={images} />
        </div>
      </section>
      <ContactSection />
    </Layout>
  )
}

export const query = graphql`
  query($lng: String) {
    cockpitAboutPage(lang: { eq: $lng }) {
      title {
        value
      }
      subtitle {
        value
      }
      text {
        value
      }
      featuredImage {
        value {
          childImageSharp {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    allCockpitGallery(filter: {lang: {eq: $lng}}, sort: {fields: cockpitCreated, order: DESC}) {
      edges {
        node {
          id
          image {
            value {
              url
              childImageSharp {
                gatsbyImageData(
                  width: 350,
                  placeholder: BLURRED
                )
              }
            }
          }
          title {
            value
          }
        }
      }
    }
  }
`

export default injectIntl(AboutPage)
